
.fira {
    font-family: 'Fira Sans Condensed', sans-serif;
    letter-spacing: 0.1em;
}
.garamond {
    font-family: 'EB Garamond', serif;
    letter-spacing: 0rem;
}
.container {
  @apply px-4;
}
canvas {
  position: fixed;
  pointer-events: none;
  z-index: 49;
}
.bg-head {
    background-image: url(../assets/img/bg-head.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
}

.bg-section {
    background-image: url(../assets/img/bg-section-bottom.png), url(../assets/img/bg-section-top.png);
    background-position: center bottom, center top;
    background-size: contain, contain;
    background-repeat: no-repeat, no-repeat;
}

.bg-military {
    background-image: url(../assets/img/military-precison.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
}

.bg-footer-01 {
    background-image: url(../assets/img/joshua-earle-footer-t4s-01.png);
    background-repeat: no-repeat;
    background-position:bottom center;
    background-size: cover;
}
.bg-footer-02 {
    background-image: url(../assets/img/joshua-earle-footer-t4s-02.png);
    background-repeat: no-repeat;
    background-position:center bottom;
    background-size: cover;
    bottom: 120px;
}

.bg-primary-modal {
  background-color: rgba(#100249,.9);
}

.scroll-moving {
    animation: bounce 0.5s;
    animation-direction: alternate;
    animation-iteration-count: infinite;

}

@keyframes bounce {
    from { transform: translate3d(0, 0, 0);     }
    to   { transform: translate3d(5rem, 0, 0); }
  }

  .rotate--90 {
      transform: rotate(-90deg);
      transform-origin: 0 100%;
  }
  iframe body.vp-center {
    align-items: flex-start !important;
  }
  .form-contact-close {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
    pointer-events: none;
  }
  .form-contact-open {
    transform: translate3d(0, -30%, 0);
    opacity: 1;
    pointer-events: inherit;
  }

  .action-btn {
    @apply bg-clickable;
    @apply text-white;
    @apply uppercase;
    @apply .rounded;
    outline: none !important;
    width: fit-content;
    transition: all .1s;
    box-shadow: 0 2px 15px 0 rgba(25,110,227,0.30);
    padding: 0.85rem 1.5rem 0.65rem 1.5rem;
  }

  .action-btn:hover {
    @apply .bg-clickable-hover;
    transform: scale(1.05);
  }

  .action-btn:active{
    @apply .bg-clickable-active;
    transform: scale(1);
    outline: none;
  }
  
  .sticky2 {
    position: sticky;
    top: 50px;
    left: 0;
    right: 0;
    display: block;
    z-index: 1;
  }

.owl-stage-outer {
  overflow: visible !important;
}
.owl-stage {
  display: flex !important;
}
.owl-item-active {
  height: auto !important;
}
div.owl-carousel div.owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
div.owl-carousel button.owl-dot {
  background-color: rgba(#100249, .2);
  height: 16px;
  width: 16px;
  border-radius: 100%;
  margin:30px 8px;
  transition: all .2s;
  outline: none;
}
div.owl-carousel button.owl-dot:hover {
  background-color: #196EE3;
}
div.owl-carousel button.owl-dot.active{
  background-color: #F06449;
  height: 20px;
  width: 20px;
}

@keyframes pop-img { 
  0% {
    transform:translateY(0);
  }
  5% {
    transform:translateY(-10px);
  }
  10% {
    transform:translateY(0);
  }
}

.pop-img img {
  -webkit-animation: pop-img 1.5s infinite; /* Safari 4+ */
  -moz-animation:    pop-img 1.5s infinite; /* Fx 5+ */
  -o-animation:      pop-img 1.5s infinite; /* Opera 12+ */
  animation:         pop-img 1.5s infinite; /* IE 10+, Fx 29+ */
}

.pop-img div:nth-child(2) div img {
  animation-delay: .1s;
}
.pop-img div:nth-child(3) div img {
  animation-delay: .2s;
}
.pop-img div:nth-child(4) div img {
  animation-delay: .3s;
}
.pop-img div:nth-child(4) div:nth-child(2) img {
  animation-delay: .4s;
}
.pop-img div:nth-child(5) div img {
  animation-delay: .5s;
}
.pop-img div:nth-child(6) div img {
  animation-delay: .6s;
}

.shadow-xl {
  box-shadow: 0 40px 40px 0 rgba(16,2,73,0.10);
}

.blur {
  filter: blur(8px);
}
.transition-300 {
  transition: all .3s;
}

.form-group {
  position:relative;  

  & + .form-group {
    margin-top: 30px;
  }
}

.form-label {
  position: absolute;
  left: 0;
  top: 10px;
  color: white;
  z-index: 10;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
}

.focused .form-label {
  transform: translateY(-155%);
  font-size: .75em;
}

.form-input {
  background-color: transparent;
  position: relative;
  padding: 12px 10px 5px 10px;
  color: white;
  width: 100%;
  outline: 0;
  border: 0;
  box-shadow: 0 1px 0 0 #e5e5e5;
  -webkit-box-shadow: 0 1px 0 0 #e5e5e5;
  -moz-box-shadow: 0 1px 0 0 #e5e5e5;
  transition: box-shadow 150ms ease-out;
  
  &:focus {
    -webkit-box-shadow: 0 2px 0 0 #F06449;
    -moz-box-shadow: 0 2px 0 0 #F06449;
  }
}

.form-input.filled {
  -webkit-box-shadow: 0 2px 0 0 lightgreen;
  -moz-box-shadow: 0 2px 0 0 lightgreen;
  color: white;
}

input:-internal-autofill-previewed, input:-internal-autofill-selected, textarea:-internal-autofill-previewed, textarea:-internal-autofill-selected, select:-internal-autofill-previewed, select:-internal-autofill-selected  {
  background-color: transparent !important;
}
.shake {
  animation-name: shake;
  animation-duration: 1s;
}

@keyframes shake {
  from,
  to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
  }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
 }
 
 .spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-top-color: #7cb6f8;
  animation: spinner .6s linear infinite;
 }
 .spinner {
     text-indent: -9999px;
 }

 .arrow-top{
   position: relative;
   height: 12px;
   margin-bottom: 10px;
   display: flex;
   align-content: flex-start;
   top:0;
   left:0;
 }
 .arrow-top span {
    display: block;
    width: 2px;
    background-color: white;
    top: 0;
    position: absolute;
 }
 .arrow-top span:nth-child(2) {
  height: 18px;
  left:-1px;
  top: 1px;
 }
 .arrow-top span:nth-child(1) {
  transform: rotate(45deg);
  height: 9px;
  right: 2px;
 }
 .arrow-top span:nth-child(3) {
  transform: rotate(-45deg);
  height: 9px;
  left: 2px;
 }

 .check-wrap, .cross-wrap {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  animation: wrap 0.2s ease-in-out forwards;
  animation-delay: 0;
  transform: scale(0);
}
.check-wrap::before, .check-wrap::after, .cross-wrap::before, .cross-wrap::after  {
  content: '';
  position: absolute;
  background-color: white;
  width: 0;
  height: 5px;
  transform-origin: left;
  animation-duration: 0.15s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.check-wrap::before {
  top: 34px;
  left: 21px;
  transform: rotate(45deg);
  animation-name: left;
  animation-delay: 0.15s;
}
.cross-wrap::before {
  top: 24px;
  left: 25px;
  transform: rotate(45deg);
  animation-name: right;
  animation-delay: 0.3s;
}
.check-wrap::after{
  top: 44px;
  left: 29px;
  transform: rotate(-45deg);
  animation-name: right;
  animation-delay: 0.3s;
}
.cross-wrap::after {
  top: 44px;
  left: 25px;
  transform: rotate(-45deg);
  animation-name: right;
  animation-delay: 0.3s;
}


@keyframes wrap {
  0% {
    background-color: transparent;
    transform: scale(0);
  }
  90% {
    transform: scale(1.2);
  }
  100% {
    @apply .bg-secondary;
    transform: scale(1);
  }
}
@keyframes left {
  0% {
    width: 0;
  }
  100% {
    width: 15px;
  }
}
@keyframes right {
  0% {
    width: 0;
  }
  90%{
    width: 32px;
  }
  100% {
    width: 30px;
  }
}


@responsive {
  .bw {
      transition: all .2s;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      &:hover {
          -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
      }
  }
}
@variants hover {
  .opacity-1 {
    opacity: 1;
  }
}
